<template>
  <div id="login">

  <section class="container mt-5">

    <div class="row justify-content-center">
    <Form @submit="onSubmit" :validation-schema="schema">

      <TextInput
        name="username"
        type="text"
        label="Username"
      />
      <TextInput
        name="dob"
        type="date"
        label="Date of birth"
        tooltip="Date of birth is needed to confirm age restriction."
        placeholder="yyyy-mm-dd"
      />
      <div class="row">
        <div class="col">
            <button type="submit" class="btn btn-custom" v-on:click="login">Set</button>
        </div>
      </div>

    </Form>

    </div>
  </section>

  <Footer/>
  </div>
</template>

<script>
import Api from '../scripts/api'
import Footer from '../components/global/Footer'

import { Form } from "vee-validate";
import * as Yup from "yup";
import TextInput from "../components/Form/TextInput";


export default {
    components: {
        Footer,
        Form,
        TextInput
    },
  setup() {
    var schema = Yup.object().shape({
        username: Yup.string().required(),
        dob: Yup.date().required(),
      });
      return {
        schema,
      };
    },
    props: {
      account: Object
    },
    methods: {
      onSubmit: function(values) {
        this.set(values.username, values.dob);
      },
      set: async function(username, dob) {
        var result = await Api.setAdditionalData(username, dob);
        
        if(result.status == 410) return this.$toast.error('You must be at least 18 years old to make an account.')
        if(result.status == 200) {
          window.location.replace("/");
        }
      },
    }
}
</script>

<style>

:root {
  --primary-color: #0071fe;
  --error-color: #f23648;
  --error-bg-color: #fddfe2;
  --success-color: #21a67a;
  --success-bg-color: #e0eee4;
}
.submit-btn {
  background: var(--primary-color);
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  display: block;
  width: 100%;
  border-radius: 7px;
  margin-top: 40px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  transform: scale(1.1);
}
</style>
